import "./styles/index.scss";

const astridWelcome = document.querySelector(".astrid-welcome");
const astridClose = document.querySelector(".welcome-close");
const astridBlog = document.querySelector(".astrid-blog");
const BlogOpen = document.querySelector(".blog-btn");
const BlogClose = document.querySelector(".blog-close");
const fixed = document.body;

window.addEventListener("load", () => {
    showWelcome();
});

//The Automatically Popup Modals

function showWelcome() {
    const timeLimit = 3;
    let i = 0;
    const timer = setInterval(function(){
        i++;
        if(i == timeLimit && (localStorage.getItem('popState') != 'shown')){
            clearInterval(timer);
            astridWelcome.classList.add("welcome");
            fixed.style.overflow = "hidden";
        }
    }, 1000);
}

astridClose.addEventListener("click", () => {
    localStorage.setItem('popState', 'shown');
    astridWelcome.classList.remove("welcome");
    fixed.style.overflow = "auto";
});

//On-Click Popup Modals

BlogOpen.addEventListener("click", () => {
    astridBlog.classList.add("blog");
    fixed.style.overflow = "hidden";
});

BlogClose.addEventListener("click", () => {
    astridBlog.classList.remove("blog");
    fixed.style.overflow = "auto";
});

const scrollTop = document.querySelector('.scrollTop');

scrollTop.addEventListener('click', function () {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    });
});